const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST" 
const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS" 
const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL" 

const UPDATE_DATA_REQUEST = "UPDATE_DATA_REQUEST" 
const UPDATE_DATA_SUCCESS = "UPDATE_DATA_SUCCESS" 
const UPDATE_DATA_FAIL = "UPDATE_DATA_FAIL" 

export {
    ADMIN_LOGIN_FAIL,ADMIN_LOGIN_REQUEST,ADMIN_LOGIN_SUCCESS,
    UPDATE_DATA_FAIL,UPDATE_DATA_REQUEST,UPDATE_DATA_SUCCESS
}