const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST"
const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS"
const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL"

const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST"
const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS"
const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL"

const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST"
const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"

const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST"
const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"
const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL"


const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST"
const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL"

export {
    ADD_PRODUCT_FAIL,ADD_PRODUCT_REQUEST,ADD_PRODUCT_SUCCESS,
    GET_PRODUCTS_FAIL,GET_PRODUCTS_REQUEST,GET_PRODUCTS_SUCCESS,
    GET_PRODUCT_REQUEST,GET_PRODUCT_FAIL,GET_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,UPDATE_PRODUCT_REQUEST,UPDATE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAIL,DELETE_PRODUCT_REQUEST,DELETE_PRODUCT_SUCCESS
}
